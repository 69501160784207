<template>
  <b-card>
    <b-row>
      <b-col
      cols="12"
      class="mt-1"
      >
        <h4 class="mb-2">
          Settings
        </h4>
      </b-col>
    </b-row>

    <form-wizard
      color="#94221e"
      :title="null"
      :subtitle="null"
      shape="square"
      class="mb-3"
      ref="wizard"
      :start-index="0"
      @on-complete="formSubmitted"
    >
      <!-- accoint details tab -->
      <tab-content
        title="Profile"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0 title-tab">
                Profile
              </h5>
              <small class="text-muted">*required</small>
            </b-col>
          </b-row>
          
          <b-row>
            <b-col md="12">
              <b-row>
                <b-col md="6" class="mb-1" style="display: none;">
                  <!-- media -->
                  <b-media no-body>
                    <b-media-aside>
                      <b-link>
                        <b-avatar
                          ref="previewEl"
                          rounded
                          :src="usuarioData.avatar"
                          :text="avatarText(usuarioData.fullName)"
                          v-model="usuarioData.avatar"
                          size="70px"
                        />
                      </b-link>
                      <!--/ avatar -->
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <!-- upload button -->
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="$refs.refInputEl.$el.click()"
                      >
                        Upload
                      </b-button>
                      <b-form-file
                        ref="refInputEl"
                        v-model="profileFile"
                        accept=".jpg, .png, .gif"
                        :hidden="true"
                        plain
                      />
                      <!--/ upload button -->
                    </b-media-body>
                  </b-media>
                  <!--/ media -->
                </b-col>

                <b-row>
                  <b-col sm="6">
                    <b-form-group
                      label-for="first-name"
                    >
                      <template v-slot:label>
                        First Name <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="First Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="usuarioData.firstName"
                          placeholder="First Name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      label-for="last-name"
                    >
                      <template v-slot:label>
                        Last Name <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Last Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="usuarioData.lastName"
                          placeholder="Last Name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      label="Email Address"
                      label-for="account-e-mail"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email Address"
                        rules="required"
                      >
                        <b-form-input
                          v-model="usuarioData.email"
                          name="email"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Email"
                          trim
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      label-for="contact"
                    >
                      <template v-slot:label>
                        Phone <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Phone"
                        rules="required"
                      >
                        <b-form-input
                          v-model="usuarioData.contact"
                          name="contact"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Phone"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                <!-- </b-row> -->

                <b-col sm="12" class="px-1 mt-2">
                  <h5 class="mb-0">
                    Change password
                  </h5>
                  <hr>
                </b-col>

                <!-- <b-row> -->
                  <b-col sm="6">
                  <!-- Password -->
                    <b-form-group
                      label-for="password"
                    >
                    <template v-slot:label>
                        Password <span class="text-danger">*</span>
                    </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        vid="Password"
                        rules="password"
                      >
                        <b-form-input
                          v-model="password"
                          type="password"
                          placeholder="Password"
                          :state="errors.length > 0 ? false:null"
                          required
                          trim
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <!-- Confirm Password -->
                    <b-form-group
                      label-for="password confirm"
                    >
                    <template v-slot:label>
                        Confirm Password <span class="text-danger">*</span>
                    </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Confirm Password"
                        :rules="password ? 'required|confirmed:Password' : 'confirmed:Password'"
                      >
                        <b-form-input
                          v-model="passwordConfirm"
                          :state="errors.length > 0 ? false:null"
                          type="password"
                          placeholder="Confirm Password"
                          required
                          trim
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-row>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <wizard-button v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">Before</wizard-button>
        </div>
        <div class="wizard-footer-right">
          <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle">Next</wizard-button>

          <wizard-button v-else @click.native="formSubmitted" class="wizard-footer-right finish-button" :style="props.fillButtonStyle" :disabled="cargaAction"><div class="loader" v-if="loadingWizard"></div>  {{props.isLastStep ? 'Update profile' : 'Next'}}</wizard-button>
        </div>
      </template>
    </form-wizard>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BAvatar,
  BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import { ref } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { db, auth } from '@/firebase'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
    BFormTextarea,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,

    // Form Wizard
    FormWizard,
    TabContent,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
      password: '',
      passwordConfirm: '',
      email: '',
      usuarioData: {},
      fileImg: '',
      optionsSelected: [
        { text: 'Orange', value: 'orange' },
      ],
      loadingWizard: false,
    }
  },
  computed: {
    ...mapState(['cargaAction','usuario'])
  },
  watch: {
    profileFile(val) {
        // console.log(val)
        this.fileImg = val
        // this.usuarioData.id = this.usuario.uid
        this.usuarioData.fileImg = this.fileImg
        this.editarGeneralUsuario(this.usuarioData)
        if(val) {
            const reader = new FileReader();
            reader.readAsDataURL(val);
            reader.onload = (e) => {
                // console.log(e.target.result)
                this.usuarioData.avatar = e.target.result
            }
        } else {
            this.usuarioData.avatar = ''
        }
    }
  },
  methods: {
    ...mapActions(['editarGeneralUsuario','cerrarSesion']),
    setUsuario() {
      if(this.usuario.role === 'clinician') {
        // update expiration date clinician
        var actualDate = Date.now()
        var expiredDate = moment(actualDate).add(6, 'months')
        var dateUpdateFormat = moment(expiredDate).format('LLLL')
        // console.log(moment(expiredDate).format('x'))
        // console.log(expiredDate._d)

        // this.$toast({
        //   component: ToastificationContent,
        //   position: 'top-right',
        //   props: {
        //     title: `Updated account expiration date: ${dateUpdateFormat}`,
        //     icon: 'CoffeeIcon',
        //     variant: 'success',
        //   },
        // })
        this.usuarioData.expiredDate = moment(expiredDate).format('x')
        this.usuarioData.updatedData = actualDate
      }

      const user = auth.currentUser
      if(this.password && this.passwordConfirm) {
        var obj = {id: user.uid, password: this.password}
        let dataBody = JSON.stringify({
          "id": user.uid,
          "pass": obj.password
        });
        var config = {
          'url': 'https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/data',
          'method': 'POST',
          'timeout': 0,
          'headers': {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          'data': dataBody
        }

        this.axios(config)
        .then(res => {
          if(res.status === 200) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Password changed.`,
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
            this.cerrarSesion()
          }
        })
        .catch(e => console.log(e.message))

        // this.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/data/${user.uid}/${obj.password}`)
        // .then(res => { 
        //     // console.log(res.data, res.status)
        //     if(res.status === 200) {
        //       this.$toast({
        //         component: ToastificationContent,
        //         position: 'top-right',
        //         props: {
        //             title: `Password changed.`,
        //             icon: 'CoffeeIcon',
        //             variant: 'success',
        //         },
        //       })
        //       this.cerrarSesion()
        //     }
        // })
      }

      if(this.usuarioData.email !== this.email) {
        this.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/email/data/${user.uid}/${this.usuarioData.email}`)
        .then(res => { 
            // console.log(res.data, res.status)
            if(res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Email changed.`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                },
              })
              this.cerrarSesion()
            }
        })
      }

      this.editarGeneralUsuario(this.usuarioData)
      this.loadingWizard = false
      this.submitForm = false
    },
    formSubmitted() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            this.loadingWizard = true
            this.submitForm = true
            this.setUsuario()
          } else {
            this.loadingWizard = false
            this.submitForm = false
          }
        })
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationAdicional() {
      return new Promise((resolve, reject) => {
        this.$refs.adicionalRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  created() {
    const user = auth.currentUser

    const ref = db.collection('usuarios').doc(user.uid)
    ref.onSnapshot(doc => {
      this.usuarioData = {}
      this.usuarioData = {
        id: doc.data().uid,
        fullName: doc.data().nombre,
        firstName: doc.data().firstName ? doc.data().firstName : doc.data().firstName,
        lastName: doc.data().lastName ? doc.data().lastName : '',
        email: doc.data().email,
        role: doc.data().role,
        avatar: doc.data().foto,
        contact: doc.data().contact
      }
      this.email = doc.data().email
    })

    // var obj = {
    //   "id": "JvoMs1sb71QmDSUd0jGViDi9kfQ2",
    //   "password": "User@123"
    // };

    // var data = JSON.stringify({
    //   "id": "JvoMs1sb71QmDSUd0jGViDi9kfQ2",
    //   "password": "User@123"
    // });
    // this.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/data/${obj.id}/${obj.password}`)
    // .then(res => { 
    //     console.log(res.data, res.status)
    // })
    // https://us-central1-nycpg-69e96.cloudfunctions.net/updateAuthUsers

    // var config = {
    //   method: 'post',
    //   url: 'https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/auth/update/usuarios/data',
    //   headers: { 
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //   },
    //   data : data
    // };

    // this.axios(config)
    // .then(res => {
    //     console.log(res.data, res.status)
    //     // this.password = ''
    //     // this.passwordConfirm = ''
    // })
    // .catch(e => {
    //     console.log(e)
    //     // this.password = ''
    //     // this.passwordConfirm = ''
    // })
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>

<style>
/* This is a css loader. It's not related to vue-form-wizard */
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  /* margin: 60px auto; */
  font-size: 2px;
  position: absolute;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #e74c3c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.vs__dropdown-toggle {
  background: #fff !important;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.title-tab {
  font-size: 24px;
  width: 700px;
}
</style>