<template>
  <div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Profile</span>
        </template>

        <account-setting-general />
      </b-tab>

      <b-tab v-if="usuarioData.role === 'admin'">
        <template #title>
          <feather-icon
            icon="ServerIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Taxonomies</span>
        </template>

        <taxonomy-setting />
      </b-tab>

      <b-tab v-if="usuarioData.role === 'admin'">
        <template #title>
          <feather-icon
            icon="SettingsIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Problem Gambling Service Delivery</span>
        </template>

        <problem-gambling-service-delivery />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { 
  BTabs, BTab, BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
  BNavItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import TaxonomySetting from './TaxonomySetting.vue'
import ProblemGamblingServiceDelivery from './ProblemGamblingServiceDelivery.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { db, auth } from '@/firebase'

export default {
  components: {
    BTabs,
    BTab,
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BNavItem,

    AccountSettingGeneral,
    TaxonomySetting,
    ProblemGamblingServiceDelivery,
  },
  data() {
    return {
      usuarioData: {},
    }
  },
  directives: {
    Ripple,
  },
  created() {
    const user = auth.currentUser

    const ref = db.collection('usuarios').doc(user.uid)
    ref.onSnapshot(doc => {
      this.usuarioData = {}
      this.usuarioData = {
        id: doc.data().uid,
        fullName: doc.data().nombre,
        email: doc.data().email,
        role: doc.data().role,
        avatar: doc.data().foto,
        contact: doc.data().contact
      }
    })
  }
}
</script>
