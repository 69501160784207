var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',[_vm._v("Problem Gambling Service Delivery")]),_c('hr'),_c('validation-observer',{ref:"addProblemGamblingServiceDelivery"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number of Help Requests","label-for":"Number of Help Requests"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number of Help Requests"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number of Help Requests","state":errors.length > 0 ? false:null},model:{value:(_vm.NoHelpRequests),callback:function ($$v) {_vm.NoHelpRequests=$$v},expression:"NoHelpRequests"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Referrals: % of treatment eligible callers scheduled for an appointment with a PGRC private practitioner","label-for":"Referrals: % of treatment eligible callers scheduled for an appointment with a PGRC private practitioner"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Referrals: % of treatment eligible callers scheduled for an appointment with a PGRC private practitioner"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Referrals: % of treatment eligible callers scheduled for an appointment with a PGRC private practitioner","state":errors.length > 0 ? false:null},model:{value:(_vm.EligibleReferrals),callback:function ($$v) {_vm.EligibleReferrals=$$v},expression:"EligibleReferrals"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Information Provided: % of callers receiving information only","label-for":"Information Provided: % of callers receiving information only"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Information Provided: % of callers receiving information only"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Information Provided: % of callers receiving information only","state":errors.length > 0 ? false:null},model:{value:(_vm.CallerInfoProvided),callback:function ($$v) {_vm.CallerInfoProvided=$$v},expression:"CallerInfoProvided"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Attended a session with a PGRC private practitioner compared to scheduled an appointment with a PGRC private practitioner (%)","label-for":"Attended a session with a PGRC private practitioner compared to scheduled an appointment with a PGRC private practitioner (%)"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Attended a session with a PGRC private practitioner compared to scheduled an appointment with a PGRC private practitioner (%)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Attended a session with a PGRC private practitioner compared to scheduled an appointment with a PGRC private practitioner (%)","state":errors.length > 0 ? false:null},model:{value:(_vm.AttendedPGRCsession),callback:function ($$v) {_vm.AttendedPGRCsession=$$v},expression:"AttendedPGRCsession"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Admitted to treatment with a PGRC private practitioner compared to attended a session with a PGRC private practitioner (%)","label-for":"Admitted to treatment with a PGRC private practitioner compared to attended a session with a PGRC private practitioner (%)"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Admitted to treatment with a PGRC private practitioner compared to attended a session with a PGRC private practitioner (%)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Admitted to treatment with a PGRC private practitioner compared to attended a session with a PGRC private practitioner (%)","state":errors.length > 0 ? false:null},model:{value:(_vm.AttendedPGRCPractitioner),callback:function ($$v) {_vm.AttendedPGRCPractitioner=$$v},expression:"AttendedPGRCPractitioner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number referred to PGRC private practitioners","label-for":"Number referred to PGRC private practitioners"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number referred to PGRC private practitioners"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number referred to PGRC private practitioners","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalReferredPGRC),callback:function ($$v) {_vm.TotalReferredPGRC=$$v},expression:"TotalReferredPGRC"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number in treatment","label-for":"Number in treatment"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number in treatment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number in treatment","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalTreatment),callback:function ($$v) {_vm.TotalTreatment=$$v},expression:"TotalTreatment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number admitted","label-for":"Number admitted"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number admitted"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number admitted","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalAdmitted),callback:function ($$v) {_vm.TotalAdmitted=$$v},expression:"TotalAdmitted"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number discharged","label-for":"Number discharged"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number discharged"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number discharged","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalDischarged),callback:function ($$v) {_vm.TotalDischarged=$$v},expression:"TotalDischarged"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number of problem gamblers in treatment","label-for":"Number of problem gamblers in treatment"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number of problem gamblers in treatment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number of problem gamblers in treatment","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalProblemGamblersTreatment),callback:function ($$v) {_vm.TotalProblemGamblersTreatment=$$v},expression:"TotalProblemGamblersTreatment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number of family members in treatment","label-for":"Number of family members in treatment"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number of family members in treatment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number of family members in treatment","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalFamilyMembersTreatment),callback:function ($$v) {_vm.TotalFamilyMembersTreatment=$$v},expression:"TotalFamilyMembersTreatment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number of unique persons treated","label-for":"Number of unique persons treated"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number of unique persons treated"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number of unique persons treated","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalUniquesTreated),callback:function ($$v) {_vm.TotalUniquesTreated=$$v},expression:"TotalUniquesTreated"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number of treatment visits","label-for":"Number of treatment visits"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number of treatment visits"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number of treatment visits","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalTreatmentVisits),callback:function ($$v) {_vm.TotalTreatmentVisits=$$v},expression:"TotalTreatmentVisits"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number of assessments completed this quarter (number of clients)","label-for":"Number of assessments completed this quarter (number of clients)"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number of assessments completed this quarter (number of clients)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number of assessments completed this quarter (number of clients)","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalAssessmentsCompletedQuarter),callback:function ($$v) {_vm.TotalAssessmentsCompletedQuarter=$$v},expression:"TotalAssessmentsCompletedQuarter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number of remote sessions (phone)","label-for":"Number of remote sessions (phone)"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number of remote sessions (phone)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number of remote sessions (phone)","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalRemoteSessionsPhone),callback:function ($$v) {_vm.TotalRemoteSessionsPhone=$$v},expression:"TotalRemoteSessionsPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number of remote sessions (video)","label-for":"Number of remote sessions (video)"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number of remote sessions (video)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number of remote sessions (video)","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalRemoteSessionsVideo),callback:function ($$v) {_vm.TotalRemoteSessionsVideo=$$v},expression:"TotalRemoteSessionsVideo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number of group counseling sessions","label-for":"Number of group counseling sessions"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number of group counseling sessions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number of group counseling sessions","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalGroupSessions),callback:function ($$v) {_vm.TotalGroupSessions=$$v},expression:"TotalGroupSessions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number of clinical supervision sessions","label-for":"Number of clinical supervision sessions"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number of clinical supervision sessions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number of clinical supervision sessions","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalClinicalSessions),callback:function ($$v) {_vm.TotalClinicalSessions=$$v},expression:"TotalClinicalSessions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number of 'not on census' (engagement) counseling sessions","label-for":"Number of 'not on census' (engagement) counseling sessions"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number of 'not on census' (engagement) counseling sessions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number of 'not on census' (engagement) counseling sessions","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalNotOnCensusSession),callback:function ($$v) {_vm.TotalNotOnCensusSession=$$v},expression:"TotalNotOnCensusSession"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number referred to OASAS outpatient treatment programs","label-for":"Number referred to OASAS outpatient treatment programs"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number referred to OASAS outpatient treatment programs"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number referred to OASAS outpatient treatment programs","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalReferredOASASOutpatient),callback:function ($$v) {_vm.TotalReferredOASASOutpatient=$$v},expression:"TotalReferredOASASOutpatient"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number referred to OASAS ATC programs","label-for":"Number referred to OASAS ATC programs"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number referred to OASAS ATC programs"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number referred to OASAS ATC programs","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalReferredOASASATC),callback:function ($$v) {_vm.TotalReferredOASASATC=$$v},expression:"TotalReferredOASASATC"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number referred to OASAS residential programs","label-for":"Number referred to OASAS residential programs"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number referred to OASAS residential programs"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number referred to OASAS residential programs","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalReferredOASASRedesidential),callback:function ($$v) {_vm.TotalReferredOASASRedesidential=$$v},expression:"TotalReferredOASASRedesidential"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number of non-English speaking callers served","label-for":"Number of non-English speaking callers served"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Number of non-English speaking callers served"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Number of non-English speaking callers served","state":errors.length > 0 ? false:null},model:{value:(_vm.TotalNonEnglishSpeakingCallersServed),callback:function ($$v) {_vm.TotalNonEnglishSpeakingCallersServed=$$v},expression:"TotalNonEnglishSpeakingCallersServed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Average number of sessions attended at discharge","label-for":"Average number of sessions attended at discharge"}},[_c('validation-provider',{attrs:{"rules":"integer","name":"Average number of sessions attended at discharge"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Average number of sessions attended at discharge","state":errors.length > 0 ? false:null},model:{value:(_vm.AvNumberSessionsAtDischarge),callback:function ($$v) {_vm.AvNumberSessionsAtDischarge=$$v},expression:"AvNumberSessionsAtDischarge"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Referral Sources","label-for":"Referral Sources"}},[_c('v-select',{attrs:{"placeholder":"Referral Sources","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.optionsReferralSources,"multiple":""},model:{value:(_vm.ReferralSources),callback:function ($$v) {_vm.ReferralSources=$$v},expression:"ReferralSources"}})],1)],1)],1),_c('b-button',{staticClass:"mr-2 float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Save ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }