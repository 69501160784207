 
<template>
    <b-card>
        <edit-taxonomy-label :stringLabelTag="stringLabelTag" :arrayLabelTag="arrayLabelTag" :typeLabelTag="typeLabelTag" />

        <b-tabs fill style="width: 100%">
            <b-tab active>
                <template #title>
                    <span>Taxonomies Clinicians</span>
                </template>

                <!-- <b-row>
                    <b-col
                    cols="12"
                    class="mt-1"
                    >
                        <h4 class="mb-2">
                        Taxonomies Clinicians
                        </h4>
                    </b-col>
                </b-row> -->
                <!-- <b-row class="mx-50"> -->
                    <!-- <b-tabs fill style="width: 100%"> -->
                        <!-- <b-tab active> -->
                            <!-- <template #title> -->
                                <!-- <feather-icon icon="UserIcon" /> -->
                        <b-form @submit.prevent="taxonomiesClinician()">
                            <h2>Profile</h2>
                            <!-- </template> -->

                            <!-- <b-form @submit.prevent="profileTaxonomies({region,program,clinicianAttributes})"> -->
                                <b-row cols="12">
                                    <b-col md="6">
                                        <b-form-group
                                            label="Region"
                                            label-for="region"
                                        >
                                            <b-form-tags
                                                v-model="region"
                                                no-outer-focus
                                            >
                                                <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                                    <b-input-group aria-controls="my-custom-tags-list">
                                                        <input
                                                            v-bind="inputAttrs"
                                                            placeholder="Press enter to Add Options"
                                                            class="form-control"
                                                            v-on="inputHandlers"
                                                        >
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="primary"
                                                                @click="addTag()"
                                                            >
                                                            Add
                                                            </b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <ul
                                                        id="my-custom-tags-list"
                                                        class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                        aria-live="polite"
                                                        aria-atomic="false"
                                                        aria-relevant="additions removals"
                                                    >
                                                        <b-card
                                                            v-for="tag in tags"
                                                            :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                            :key="tag"
                                                            tag="li"
                                                            class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                            body-class="px-50 py-0 text-nowrap"
                                                            style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                        >
                                                            <strong>{{ tag }}</strong>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="ml-25 p-25"
                                                                v-b-toggle.sidebar-right-editar-usuario
                                                                @click="editarTaxonomia({tag, tags, type: 'region'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                            </b-button>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="p-25"
                                                                @click="eliminarTaxonomia({tag, tags, type: 'region'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="XIcon" />
                                                            </b-button>
                                                        </b-card>
                                                    </ul>
                                                </template>
                                            </b-form-tags>
                                        </b-form-group>
                                    </b-col>
                                    <!-- <b-col md="6">
                                        <b-form-group
                                            label="Region"
                                            label-for="region"
                                        >
                                            <v-select
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                taggable
                                                push-tags
                                                placeholder="Press enter to Add Options"
                                                v-model="region"
                                            />
                                        </b-form-group>
                                    </b-col> -->
                                    <b-col md="6">
                                        <b-form-group
                                            label="Program"
                                            label-for="program"
                                        >
                                            <b-form-tags
                                                v-model="program"
                                                no-outer-focus
                                            >
                                                <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                                    <b-input-group aria-controls="my-custom-tags-list">
                                                        <input
                                                            v-bind="inputAttrs"
                                                            placeholder="Press enter to Add Options"
                                                            class="form-control"
                                                            v-on="inputHandlers"
                                                        >
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="primary"
                                                                @click="addTag()"
                                                            >
                                                            Add
                                                            </b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <ul
                                                        id="my-custom-tags-list"
                                                        class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                        aria-live="polite"
                                                        aria-atomic="false"
                                                        aria-relevant="additions removals"
                                                    >
                                                        <b-card
                                                            v-for="tag in tags"
                                                            :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                            :key="tag"
                                                            tag="li"
                                                            class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                            body-class="px-50 py-0 text-nowrap"
                                                            style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                        >
                                                            <strong>{{ tag }}</strong>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="ml-25 p-25"
                                                                v-b-toggle.sidebar-right-editar-usuario
                                                                @click="editarTaxonomia({tag, tags, type: 'program'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                            </b-button>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="p-25"
                                                                @click="eliminarTaxonomia({tag, tags, type: 'program'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="XIcon" />
                                                            </b-button>
                                                        </b-card>
                                                    </ul>
                                                </template>
                                            </b-form-tags>
                                        </b-form-group>
                                        <!-- <b-form-group
                                            label="Program"
                                            label-for="program"
                                        >
                                            <v-select
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                taggable
                                                push-tags
                                                placeholder="Press enter to Add Options"
                                                v-model="program"
                                            />
                                        </b-form-group> -->
                                    </b-col>
                                    <b-col md="6">
                                        <b-form-group
                                            label="Clinician Attributes"
                                            label-for="clinicianAttributes"
                                        >
                                            <b-form-tags
                                                v-model="clinicianAttributes"
                                                no-outer-focus
                                            >
                                                <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                                    <b-input-group aria-controls="my-custom-tags-list">
                                                        <input
                                                            v-bind="inputAttrs"
                                                            placeholder="Press enter to Add Options"
                                                            class="form-control"
                                                            v-on="inputHandlers"
                                                        >
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="primary"
                                                                @click="addTag()"
                                                            >
                                                            Add
                                                            </b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <ul
                                                        id="my-custom-tags-list"
                                                        class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                        aria-live="polite"
                                                        aria-atomic="false"
                                                        aria-relevant="additions removals"
                                                    >
                                                        <b-card
                                                            v-for="tag in tags"
                                                            :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                            :key="tag"
                                                            tag="li"
                                                            class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                            body-class="px-50 py-0 text-nowrap"
                                                            style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                        >
                                                            <strong>{{ tag }}</strong>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="ml-25 p-25"
                                                                v-b-toggle.sidebar-right-editar-usuario
                                                                @click="editarTaxonomia({tag, tags, type: 'clinicianAttributes'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                            </b-button>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="p-25"
                                                                @click="eliminarTaxonomia({tag, tags, type: 'clinicianAttributes'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="XIcon" />
                                                            </b-button>
                                                        </b-card>
                                                    </ul>
                                                </template>
                                            </b-form-tags>
                                        </b-form-group>
                                        <!-- <b-form-group
                                            label="Clinician Attributes"
                                            label-for="clinicianAttributes"
                                        >
                                            <v-select
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                taggable
                                                push-tags
                                                placeholder="Press enter to Add Options"
                                                v-model="clinicianAttributes"
                                            />
                                        </b-form-group> -->
                                    </b-col>
                                </b-row>
                                <!-- <b-row style="float: right;">
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        type="submit"
                                        variant="primary"
                                        class="mr-1"
                                    >
                                    Update
                                    </b-button>
                                </b-row> -->
                            <!-- </b-form> -->
                        <!-- </b-tab> -->
                        <!-- <b-tab> -->
                            <!-- <template #title> -->
                                <!-- <feather-icon icon="CheckCircleIcon" /> -->
                            <hr>
                            <h2>Clinician Details</h2>
                            <!-- </template> -->

                            <!-- <b-form @submit.prevent="clinicianDetailsTaxonomies({licenseType,insuranceTypesAcepted,specialities,languages})"> -->
                                <b-row cols="12">
                                    <b-col md="6">
                                        <b-form-group
                                            label="License type"
                                            label-for="licenseType"
                                        >
                                            <b-form-tags
                                                v-model="licenseType"
                                                no-outer-focus
                                            >
                                                <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                                    <b-input-group aria-controls="my-custom-tags-list">
                                                        <input
                                                            v-bind="inputAttrs"
                                                            placeholder="Press enter to Add Options"
                                                            class="form-control"
                                                            v-on="inputHandlers"
                                                        >
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="primary"
                                                                @click="addTag()"
                                                            >
                                                            Add
                                                            </b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <ul
                                                        id="my-custom-tags-list"
                                                        class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                        aria-live="polite"
                                                        aria-atomic="false"
                                                        aria-relevant="additions removals"
                                                    >
                                                        <b-card
                                                            v-for="tag in tags"
                                                            :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                            :key="tag"
                                                            tag="li"
                                                            class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                            body-class="px-50 py-0 text-nowrap"
                                                            style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                        >
                                                            <strong>{{ tag }}</strong>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="ml-25 p-25"
                                                                v-b-toggle.sidebar-right-editar-usuario
                                                                @click="editarTaxonomia({tag, tags, type: 'licenseType'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                            </b-button>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="p-25"
                                                                @click="eliminarTaxonomia({tag, tags, type: 'licenseType'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="XIcon" />
                                                            </b-button>
                                                        </b-card>
                                                    </ul>
                                                </template>
                                            </b-form-tags>
                                            <!-- <v-select
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                taggable
                                                push-tags
                                                placeholder="Press enter to Add Options"
                                                v-model="licenseType"
                                            /> -->
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6">
                                        <b-form-group
                                            label="Insurance types accepted"
                                            label-for="insuranceTypesAcepted"
                                        >
                                            <b-form-tags
                                                v-model="insuranceTypesAcepted"
                                                no-outer-focus
                                            >
                                                <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                                    <b-input-group aria-controls="my-custom-tags-list">
                                                        <input
                                                            v-bind="inputAttrs"
                                                            placeholder="Press enter to Add Options"
                                                            class="form-control"
                                                            v-on="inputHandlers"
                                                        >
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="primary"
                                                                @click="addTag()"
                                                            >
                                                            Add
                                                            </b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <ul
                                                        id="my-custom-tags-list"
                                                        class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                        aria-live="polite"
                                                        aria-atomic="false"
                                                        aria-relevant="additions removals"
                                                    >
                                                        <b-card
                                                            v-for="tag in tags"
                                                            :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                            :key="tag"
                                                            tag="li"
                                                            class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                            body-class="px-50 py-0 text-nowrap"
                                                            style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                        >
                                                            <strong>{{ tag }}</strong>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="ml-25 p-25"
                                                                v-b-toggle.sidebar-right-editar-usuario
                                                                @click="editarTaxonomia({tag, tags, type: 'insuranceTypesAcepted'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                            </b-button>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="p-25"
                                                                @click="eliminarTaxonomia({tag, tags, type: 'insuranceTypesAcepted'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="XIcon" />
                                                            </b-button>
                                                        </b-card>
                                                    </ul>
                                                </template>
                                            </b-form-tags>
                                            <!-- <v-select
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                taggable
                                                push-tags
                                                placeholder="Press enter to Add Options"
                                                v-model="insuranceTypesAcepted"
                                            /> -->
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6">
                                        <b-form-group
                                            label="Specialities"
                                            label-for="specialities"
                                        >
                                            <b-form-tags
                                                v-model="specialities"
                                                no-outer-focus
                                            >
                                                <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                                    <b-input-group aria-controls="my-custom-tags-list">
                                                        <input
                                                            v-bind="inputAttrs"
                                                            placeholder="Press enter to Add Options"
                                                            class="form-control"
                                                            v-on="inputHandlers"
                                                        >
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="primary"
                                                                @click="addTag()"
                                                            >
                                                            Add
                                                            </b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <ul
                                                        id="my-custom-tags-list"
                                                        class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                        aria-live="polite"
                                                        aria-atomic="false"
                                                        aria-relevant="additions removals"
                                                    >
                                                        <b-card
                                                            v-for="tag in tags"
                                                            :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                            :key="tag"
                                                            tag="li"
                                                            class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                            body-class="px-50 py-0 text-nowrap"
                                                            style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                        >
                                                            <strong>{{ tag }}</strong>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="ml-25 p-25"
                                                                v-b-toggle.sidebar-right-editar-usuario
                                                                @click="editarTaxonomia({tag, tags, type: 'specialities'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                            </b-button>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="p-25"
                                                                @click="eliminarTaxonomia({tag, tags, type: 'specialities'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="XIcon" />
                                                            </b-button>
                                                        </b-card>
                                                    </ul>
                                                </template>
                                            </b-form-tags>
                                            <!-- <v-select
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                taggable
                                                push-tags
                                                placeholder="Press enter to Add Options"
                                                v-model="specialities"
                                            /> -->
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6">
                                        <b-form-group
                                            label="Languages"
                                            label-for="languages"
                                        >
                                            <b-form-tags
                                                v-model="languages"
                                                no-outer-focus
                                            >
                                                <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                                    <b-input-group aria-controls="my-custom-tags-list">
                                                        <input
                                                            v-bind="inputAttrs"
                                                            placeholder="Press enter to Add Options"
                                                            class="form-control"
                                                            v-on="inputHandlers"
                                                        >
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="primary"
                                                                @click="addTag()"
                                                            >
                                                            Add
                                                            </b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <ul
                                                        id="my-custom-tags-list"
                                                        class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                        aria-live="polite"
                                                        aria-atomic="false"
                                                        aria-relevant="additions removals"
                                                    >
                                                        <b-card
                                                            v-for="tag in tags"
                                                            :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                            :key="tag"
                                                            tag="li"
                                                            class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                            body-class="px-50 py-0 text-nowrap"
                                                            style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                        >
                                                            <strong>{{ tag }}</strong>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="ml-25 p-25"
                                                                v-b-toggle.sidebar-right-editar-usuario
                                                                @click="editarTaxonomia({tag, tags, type: 'languages'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                            </b-button>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="p-25"
                                                                @click="eliminarTaxonomia({tag, tags, type: 'languages'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="XIcon" />
                                                            </b-button>
                                                        </b-card>
                                                    </ul>
                                                </template>
                                            </b-form-tags>
                                            <!-- <v-select
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                taggable
                                                push-tags
                                                placeholder="Press enter to Add Options"
                                                v-model="languages"
                                            /> -->
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <!-- <b-row style="float: right;">
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        type="submit"
                                        variant="primary"
                                        class="mr-1"
                                    >
                                    Update
                                    </b-button>
                                </b-row> -->
                            <!-- </b-form> -->
                        <!-- </b-tab> -->
                        <!-- <b-tab> -->
                            <!-- <template #title> -->
                                <!-- <feather-icon icon="GitCommitIcon" /> -->
                        <!-- <b-row cols="12"> -->
                            <!-- <b-col md="12"> -->
                            <hr>
                            <h2>Tracking Details</h2>
                                <b-row cols="12">
                                    <b-col md="6">
                                        <b-form-group
                                            label="Regional CFE Assigned"
                                            label-for="regionalCFEAssigned"
                                        >
                                            <b-form-tags
                                                v-model="regionalCFEAssigned"
                                                no-outer-focus
                                            >
                                                <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                                    <b-input-group aria-controls="my-custom-tags-list">
                                                        <input
                                                            v-bind="inputAttrs"
                                                            placeholder="Press enter to Add Options"
                                                            class="form-control"
                                                            v-on="inputHandlers"
                                                        >
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="primary"
                                                                @click="addTag()"
                                                            >
                                                            Add
                                                            </b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <ul
                                                        id="my-custom-tags-list"
                                                        class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                        aria-live="polite"
                                                        aria-atomic="false"
                                                        aria-relevant="additions removals"
                                                    >
                                                        <b-card
                                                            v-for="tag in tags"
                                                            :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                            :key="tag"
                                                            tag="li"
                                                            class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                            body-class="px-50 py-0 text-nowrap"
                                                            style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                        >
                                                            <strong>{{ tag }}</strong>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="ml-25 p-25"
                                                                v-b-toggle.sidebar-right-editar-usuario
                                                                @click="editarTaxonomia({tag, tags, type: 'regionalCFEAssigned'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                            </b-button>
                                                            <b-button
                                                                variant="flat"
                                                                size="sm"
                                                                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                                class="p-25"
                                                                @click="eliminarTaxonomia({tag, tags, type: 'regionalCFEAssigned'})"
                                                            >
                                                                <feather-icon style="color: #fff;" icon="XIcon" />
                                                            </b-button>
                                                        </b-card>
                                                    </ul>
                                                </template>
                                            </b-form-tags>
                                            <!-- <v-select
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                taggable
                                                push-tags
                                                placeholder="Press enter to Add Options"
                                                v-model="regionalCFEAssigned"
                                            /> -->
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row style="float: right;">
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        type="submit"
                                        variant="primary"
                                        class="mr-1"
                                    >
                                    Update
                                    </b-button>
                                </b-row>
                        </b-form>
                        <!-- </b-tab> -->
                    <!-- </b-tabs> -->
                <!-- </b-row> -->
            </b-tab>
        
            <b-tab>
                <template #title>
                    <span>Taxonomies Clients</span>
                </template>

                <!-- <b-row>
                    <b-col
                    cols="12"
                    class="mt-1"
                    >
                        <h4 class="mb-2">
                        Taxonomies Clients
                        </h4>
                    </b-col>
                </b-row> -->
                <h4>Taxonomies Clients</h4>
                <hr>
                <b-row class="mx-50">
                    <b-form @submit.prevent="taxonomiesClients({countryOfResidence,insuranceType,primaryLanguage,race,householdIncome,gamblingTypes,employmentStatus,educationLevels,involvedWithRecoveryGroups,gender,referralOptions,recoverySupportServices,paymentArrangement})">
                        <b-row cols="12">
                            <b-col md="6">
                                <b-form-group
                                    label="Referral Options"
                                    label-for="Referral Options"
                                >
                                    <b-form-tags
                                        v-model="referralOptions"
                                        no-outer-focus
                                    >
                                        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                            <b-input-group aria-controls="my-custom-tags-list">
                                                <input
                                                    v-bind="inputAttrs"
                                                    placeholder="Press enter to Add Options"
                                                    class="form-control"
                                                    v-on="inputHandlers"
                                                >
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="addTag()"
                                                    >
                                                    Add
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <ul
                                                id="my-custom-tags-list"
                                                class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions removals"
                                            >
                                                <b-card
                                                    v-for="tag in tags"
                                                    :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                    :key="tag"
                                                    tag="li"
                                                    class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                    body-class="px-50 py-0 text-nowrap"
                                                    style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                >
                                                    <strong>{{ tag }}</strong>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="ml-25 p-25"
                                                        v-b-toggle.sidebar-right-editar-usuario
                                                        @click="editarTaxonomia({tag, tags, type: 'referralOptions'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                    </b-button>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="p-25"
                                                        @click="eliminarTaxonomia({tag, tags, type: 'referralOptions'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="XIcon" />
                                                    </b-button>
                                                </b-card>
                                            </ul>
                                        </template>
                                    </b-form-tags>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    label="Recovery Support Services"
                                    label-for="Recovery Support Services"
                                >
                                    <b-form-tags
                                        v-model="recoverySupportServices"
                                        no-outer-focus
                                    >
                                        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                            <b-input-group aria-controls="my-custom-tags-list">
                                                <input
                                                    v-bind="inputAttrs"
                                                    placeholder="Press enter to Add Options"
                                                    class="form-control"
                                                    v-on="inputHandlers"
                                                >
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="addTag()"
                                                    >
                                                    Add
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <ul
                                                id="my-custom-tags-list"
                                                class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions removals"
                                            >
                                                <b-card
                                                    v-for="tag in tags"
                                                    :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                    :key="tag"
                                                    tag="li"
                                                    class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                    body-class="px-50 py-0 text-nowrap"
                                                    style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                >
                                                    <strong>{{ tag }}</strong>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="ml-25 p-25"
                                                        v-b-toggle.sidebar-right-editar-usuario
                                                        @click="editarTaxonomia({tag, tags, type: 'recoverySupportServices'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                    </b-button>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="p-25"
                                                        @click="eliminarTaxonomia({tag, tags, type: 'recoverySupportServices'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="XIcon" />
                                                    </b-button>
                                                </b-card>
                                            </ul>
                                        </template>
                                    </b-form-tags>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    label="Country of Residence"
                                    label-for="country of Residence"
                                >
                                    <b-form-tags
                                        v-model="countryOfResidence"
                                        no-outer-focus
                                    >
                                        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                            <b-input-group aria-controls="my-custom-tags-list">
                                                <input
                                                    v-bind="inputAttrs"
                                                    placeholder="Press enter to Add Options"
                                                    class="form-control"
                                                    v-on="inputHandlers"
                                                >
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="addTag()"
                                                    >
                                                    Add
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <ul
                                                id="my-custom-tags-list"
                                                class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions removals"
                                            >
                                                <b-card
                                                    v-for="tag in tags"
                                                    :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                    :key="tag"
                                                    tag="li"
                                                    class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                    body-class="px-50 py-0 text-nowrap"
                                                    style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                >
                                                    <strong>{{ tag }}</strong>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="ml-25 p-25"
                                                        v-b-toggle.sidebar-right-editar-usuario
                                                        @click="editarTaxonomia({tag, tags, type: 'countryOfResidence'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                    </b-button>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="p-25"
                                                        @click="eliminarTaxonomia({tag, tags, type: 'countryOfResidence'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="XIcon" />
                                                    </b-button>
                                                </b-card>
                                            </ul>
                                        </template>
                                    </b-form-tags>
                                    <!-- <v-select
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        multiple
                                        taggable
                                        push-tags
                                        placeholder="Press enter to Add Options"
                                        v-model="countryOfResidence"
                                    /> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    label="Insurance type"
                                    label-for="insurance type"
                                >
                                    <b-form-tags
                                        v-model="insuranceType"
                                        no-outer-focus
                                    >
                                        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                            <b-input-group aria-controls="my-custom-tags-list">
                                                <input
                                                    v-bind="inputAttrs"
                                                    placeholder="Press enter to Add Options"
                                                    class="form-control"
                                                    v-on="inputHandlers"
                                                >
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="addTag()"
                                                    >
                                                    Add
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <ul
                                                id="my-custom-tags-list"
                                                class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions removals"
                                            >
                                                <b-card
                                                    v-for="tag in tags"
                                                    :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                    :key="tag"
                                                    tag="li"
                                                    class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                    body-class="px-50 py-0 text-nowrap"
                                                    style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                >
                                                    <strong>{{ tag }}</strong>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="ml-25 p-25"
                                                        v-b-toggle.sidebar-right-editar-usuario
                                                        @click="editarTaxonomia({tag, tags, type: 'insuranceType'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                    </b-button>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="p-25"
                                                        @click="eliminarTaxonomia({tag, tags, type: 'insuranceType'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="XIcon" />
                                                    </b-button>
                                                </b-card>
                                            </ul>
                                        </template>
                                    </b-form-tags>
                                    <!-- <v-select
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        multiple
                                        taggable
                                        push-tags
                                        placeholder="Press enter to Add Options"
                                        v-model="insuranceType"
                                    /> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    label="Primary Language"
                                    label-for="primary language"
                                >
                                    <b-form-tags
                                        v-model="primaryLanguage"
                                        no-outer-focus
                                    >
                                        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                            <b-input-group aria-controls="my-custom-tags-list">
                                                <input
                                                    v-bind="inputAttrs"
                                                    placeholder="Press enter to Add Options"
                                                    class="form-control"
                                                    v-on="inputHandlers"
                                                >
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="addTag()"
                                                    >
                                                    Add
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <ul
                                                id="my-custom-tags-list"
                                                class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions removals"
                                            >
                                                <b-card
                                                    v-for="tag in tags"
                                                    :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                    :key="tag"
                                                    tag="li"
                                                    class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                    body-class="px-50 py-0 text-nowrap"
                                                    style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                >
                                                    <strong>{{ tag }}</strong>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="ml-25 p-25"
                                                        v-b-toggle.sidebar-right-editar-usuario
                                                        @click="editarTaxonomia({tag, tags, type: 'primaryLanguage'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                    </b-button>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="p-25"
                                                        @click="eliminarTaxonomia({tag, tags, type: 'primaryLanguage'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="XIcon" />
                                                    </b-button>
                                                </b-card>
                                            </ul>
                                        </template>
                                    </b-form-tags>
                                    <!-- <v-select
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        multiple
                                        taggable
                                        push-tags
                                        placeholder="Press enter to Add Options"
                                        v-model="primaryLanguage"
                                    /> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    label="Race"
                                    label-for="race"
                                >
                                    <b-form-tags
                                        v-model="race"
                                        no-outer-focus
                                    >
                                        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                            <b-input-group aria-controls="my-custom-tags-list">
                                                <input
                                                    v-bind="inputAttrs"
                                                    placeholder="Press enter to Add Options"
                                                    class="form-control"
                                                    v-on="inputHandlers"
                                                >
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="addTag()"
                                                    >
                                                    Add
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <ul
                                                id="my-custom-tags-list"
                                                class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions removals"
                                            >
                                                <b-card
                                                    v-for="tag in tags"
                                                    :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                    :key="tag"
                                                    tag="li"
                                                    class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                    body-class="px-50 py-0 text-nowrap"
                                                    style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                >
                                                    <strong>{{ tag }}</strong>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="ml-25 p-25"
                                                        v-b-toggle.sidebar-right-editar-usuario
                                                        @click="editarTaxonomia({tag, tags, type: 'race'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                    </b-button>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="p-25"
                                                        @click="eliminarTaxonomia({tag, tags, type: 'race'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="XIcon" />
                                                    </b-button>
                                                </b-card>
                                            </ul>
                                        </template>
                                    </b-form-tags>
                                    <!-- <v-select
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        multiple
                                        taggable
                                        push-tags
                                        placeholder="Press enter to Add Options"
                                        v-model="race"
                                    /> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    label="Household Income"
                                    label-for="household income"
                                >
                                    <b-form-tags
                                        v-model="householdIncome"
                                        no-outer-focus
                                    >
                                        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                            <b-input-group aria-controls="my-custom-tags-list">
                                                <input
                                                    v-bind="inputAttrs"
                                                    placeholder="Press enter to Add Options"
                                                    class="form-control"
                                                    v-on="inputHandlers"
                                                >
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="addTag()"
                                                    >
                                                    Add
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <ul
                                                id="my-custom-tags-list"
                                                class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions removals"
                                            >
                                                <b-card
                                                    v-for="tag in tags"
                                                    :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                    :key="tag"
                                                    tag="li"
                                                    class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                    body-class="px-50 py-0 text-nowrap"
                                                    style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                >
                                                    <strong>{{ tag }}</strong>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="ml-25 p-25"
                                                        v-b-toggle.sidebar-right-editar-usuario
                                                        @click="editarTaxonomia({tag, tags, type: 'householdIncome'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                    </b-button>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="p-25"
                                                        @click="eliminarTaxonomia({tag, tags, type: 'householdIncome'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="XIcon" />
                                                    </b-button>
                                                </b-card>
                                            </ul>
                                        </template>
                                    </b-form-tags>
                                    <!-- <v-select
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        multiple
                                        taggable
                                        push-tags
                                        placeholder="Press enter to Add Options"
                                        v-model="householdIncome"
                                    /> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    label="Primary Gambling Type"
                                    label-for="gambling types"
                                >
                                    <b-form-tags
                                        v-model="gamblingTypes"
                                        no-outer-focus
                                    >
                                        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                            <b-input-group aria-controls="my-custom-tags-list">
                                                <input
                                                    v-bind="inputAttrs"
                                                    placeholder="Press enter to Add Options"
                                                    class="form-control"
                                                    v-on="inputHandlers"
                                                >
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="addTag()"
                                                    >
                                                    Add
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <ul
                                                id="my-custom-tags-list"
                                                class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions removals"
                                            >
                                                <b-card
                                                    v-for="tag in tags"
                                                    :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                    :key="tag"
                                                    tag="li"
                                                    class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                    body-class="px-50 py-0 text-nowrap"
                                                    style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                >
                                                    <strong>{{ tag }}</strong>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="ml-25 p-25"
                                                        v-b-toggle.sidebar-right-editar-usuario
                                                        @click="editarTaxonomia({tag, tags, type: 'gamblingTypes'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                    </b-button>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="p-25"
                                                        @click="eliminarTaxonomia({tag, tags, type: 'gamblingTypes'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="XIcon" />
                                                    </b-button>
                                                </b-card>
                                            </ul>
                                        </template>
                                    </b-form-tags>
                                    <!-- <v-select
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        multiple
                                        taggable
                                        push-tags
                                        placeholder="Press enter to Add Options"
                                        v-model="gamblingTypes"
                                    /> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    label="Employment Status"
                                    label-for="employment status"
                                >
                                    <b-form-tags
                                        v-model="employmentStatus"
                                        no-outer-focus
                                    >
                                        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                            <b-input-group aria-controls="my-custom-tags-list">
                                                <input
                                                    v-bind="inputAttrs"
                                                    placeholder="Press enter to Add Options"
                                                    class="form-control"
                                                    v-on="inputHandlers"
                                                >
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="addTag()"
                                                    >
                                                    Add
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <ul
                                                id="my-custom-tags-list"
                                                class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions removals"
                                            >
                                                <b-card
                                                    v-for="tag in tags"
                                                    :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                    :key="tag"
                                                    tag="li"
                                                    class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                    body-class="px-50 py-0 text-nowrap"
                                                    style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                >
                                                    <strong>{{ tag }}</strong>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="ml-25 p-25"
                                                        v-b-toggle.sidebar-right-editar-usuario
                                                        @click="editarTaxonomia({tag, tags, type: 'employmentStatus'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                    </b-button>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="p-25"
                                                        @click="eliminarTaxonomia({tag, tags, type: 'employmentStatus'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="XIcon" />
                                                    </b-button>
                                                </b-card>
                                            </ul>
                                        </template>
                                    </b-form-tags>
                                    <!-- <v-select
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        multiple
                                        taggable
                                        push-tags
                                        placeholder="Press enter to Add Options"
                                        v-model="employmentStatus"
                                    /> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    label="Education Levels"
                                    label-for="education levels"
                                >
                                    <b-form-tags
                                        v-model="educationLevels"
                                        no-outer-focus
                                    >
                                        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                            <b-input-group aria-controls="my-custom-tags-list">
                                                <input
                                                    v-bind="inputAttrs"
                                                    placeholder="Press enter to Add Options"
                                                    class="form-control"
                                                    v-on="inputHandlers"
                                                >
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="addTag()"
                                                    >
                                                    Add
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <ul
                                                id="my-custom-tags-list"
                                                class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions removals"
                                            >
                                                <b-card
                                                    v-for="tag in tags"
                                                    :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                    :key="tag"
                                                    tag="li"
                                                    class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                    body-class="px-50 py-0 text-nowrap"
                                                    style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                >
                                                    <strong>{{ tag }}</strong>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="ml-25 p-25"
                                                        v-b-toggle.sidebar-right-editar-usuario
                                                        @click="editarTaxonomia({tag, tags, type: 'educationLevels'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                    </b-button>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="p-25"
                                                        @click="eliminarTaxonomia({tag, tags, type: 'educationLevels'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="XIcon" />
                                                    </b-button>
                                                </b-card>
                                            </ul>
                                        </template>
                                    </b-form-tags>
                                    <!-- <v-select
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        multiple
                                        taggable
                                        push-tags
                                        placeholder="Press enter to Add Options"
                                        v-model="educationLevels"
                                    /> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    label="Involved With Recovery Groups?"
                                    label-for="involved with recovery groups"
                                >
                                    <b-form-tags
                                        v-model="involvedWithRecoveryGroups"
                                        no-outer-focus
                                    >
                                        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                            <b-input-group aria-controls="my-custom-tags-list">
                                                <input
                                                    v-bind="inputAttrs"
                                                    placeholder="Press enter to Add Options"
                                                    class="form-control"
                                                    v-on="inputHandlers"
                                                >
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="addTag()"
                                                    >
                                                    Add
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <ul
                                                id="my-custom-tags-list"
                                                class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions removals"
                                            >
                                                <b-card
                                                    v-for="tag in tags"
                                                    :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                    :key="tag"
                                                    tag="li"
                                                    class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                    body-class="px-50 py-0 text-nowrap"
                                                    style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                >
                                                    <strong>{{ tag }}</strong>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="ml-25 p-25"
                                                        v-b-toggle.sidebar-right-editar-usuario
                                                        @click="editarTaxonomia({tag, tags, type: 'involvedWithRecoveryGroups'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                    </b-button>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="p-25"
                                                        @click="eliminarTaxonomia({tag, tags, type: 'involvedWithRecoveryGroups'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="XIcon" />
                                                    </b-button>
                                                </b-card>
                                            </ul>
                                        </template>
                                    </b-form-tags>
                                    <!-- <v-select
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        multiple
                                        taggable
                                        push-tags
                                        placeholder="Press enter to Add Options"
                                        v-model="involvedWithRecoveryGroups"
                                    /> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    label="Gender"
                                    label-for="gender"
                                >
                                    <b-form-tags
                                        v-model="gender"
                                        no-outer-focus
                                    >
                                        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                            <b-input-group aria-controls="my-custom-tags-list">
                                                <input
                                                    v-bind="inputAttrs"
                                                    placeholder="Press enter to Add Options"
                                                    class="form-control"
                                                    v-on="inputHandlers"
                                                >
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="addTag()"
                                                    >
                                                    Add
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <ul
                                                id="my-custom-tags-list"
                                                class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions removals"
                                            >
                                                <b-card
                                                    v-for="tag in tags"
                                                    :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                    :key="tag"
                                                    tag="li"
                                                    class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                    body-class="px-50 py-0 text-nowrap"
                                                    style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                >
                                                    <strong>{{ tag }}</strong>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="ml-25 p-25"
                                                        v-b-toggle.sidebar-right-editar-usuario
                                                        @click="editarTaxonomia({tag, tags, type: 'gender'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                    </b-button>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="p-25"
                                                        @click="eliminarTaxonomia({tag, tags, type: 'gender'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="XIcon" />
                                                    </b-button>
                                                </b-card>
                                            </ul>
                                        </template>
                                    </b-form-tags>
                                    <!-- <v-select
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        multiple
                                        taggable
                                        push-tags
                                        placeholder="Press enter to Add Options"
                                        v-model="gender"
                                    /> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-form-group
                                    label="Payment Arrangement"
                                    label-for="Payment Arrangement"
                                >
                                    <b-form-tags
                                        v-model="paymentArrangement"
                                        no-outer-focus
                                    >
                                        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
                                            <b-input-group aria-controls="my-custom-tags-list">
                                                <input
                                                    v-bind="inputAttrs"
                                                    placeholder="Press enter to Add Options"
                                                    class="form-control"
                                                    v-on="inputHandlers"
                                                >
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="addTag()"
                                                    >
                                                    Add
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <ul
                                                id="my-custom-tags-list"
                                                class="list-unstyled d-inline-flex flex-wrap mb-0"
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions removals"
                                            >
                                                <b-card
                                                    v-for="tag in tags"
                                                    :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                    :key="tag"
                                                    tag="li"
                                                    class="shadow-none border mt-25 mr-25 mb-0 p-0"
                                                    body-class="px-50 py-0 text-nowrap"
                                                    style="background: #94221e; color: #fff; font-size: 0.9rem;"
                                                >
                                                    <strong>{{ tag }}</strong>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="ml-25 p-25"
                                                        v-b-toggle.sidebar-right-editar-usuario
                                                        @click="editarTaxonomia({tag, tags, type: 'paymentArrangement'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="Edit2Icon" />
                                                    </b-button>
                                                    <b-button
                                                        variant="flat"
                                                        size="sm"
                                                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                                                        class="p-25"
                                                        @click="eliminarTaxonomia({tag, tags, type: 'paymentArrangement'})"
                                                    >
                                                        <feather-icon style="color: #fff;" icon="XIcon" />
                                                    </b-button>
                                                </b-card>
                                            </ul>
                                        </template>
                                    </b-form-tags>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row style="float: right;">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="submit"
                                variant="primary"
                                class="mr-1"
                            >
                            Update
                            </b-button>
                        </b-row>
                    </b-form>
                </b-row>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
import { BTabs, BTab, BCardText, BCard, BRow, BCol, BFormGroup, BForm, BButton, BFormTags, BInputGroup, BInputGroupAppend, BSidebar, VBToggle } from 'bootstrap-vue'
import EditTaxonomyLabel from './Sidebar/EditTaxonomyLabel.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import { db } from '@/firebase'

export default {
    data() {
        return {
            // Taxonomies Clinicians
            // profile fields
            region: [],
            program: [],
            clinicianAttributes: [],

            // clinician details fields
            licenseType: [],
            insuranceTypesAcepted: [],
            specialities: [],
            languages: [],

            // tracking details fields
            regionalCFEAssigned: [],

            // Taxonomies Clients
            countryOfResidence: [],
            insuranceType: [],
            primaryLanguage: [],
            race: [],
            householdIncome: [],
            gamblingTypes: [],
            employmentStatus: [],
            educationLevels: [],
            involvedWithRecoveryGroups: [],
            gender: [],
            referralOptions: [],
            recoverySupportServices: [],
            paymentArrangement: [],

            // variables sidebar
            stringLabelTag: '',
            arrayLabelTag: '',
            typeLabelTag: '',
        }
    },
    components: {
        BCardText,
        BTabs,
        BTab,
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BForm,
        BButton,
        BFormTags,
        BInputGroup,
        BInputGroupAppend,
        BSidebar,

        vSelect,
        EditTaxonomyLabel,
    },
    directives: {
        Ripple,
        'b-toggle': VBToggle,
    },
    methods: {
        ...mapActions(['updateProfileTaxonomies','updateClinicianDetailsTaxonomies','updateTrackingDetailsTaxonomies','updateTaxonomiesClients']),
        profileTaxonomies(payload) {
            // submit
            this.updateProfileTaxonomies(payload)
        },
        clinicianDetailsTaxonomies(payload) {
            // submit
            this.updateClinicianDetailsTaxonomies(payload)
        },
        trackingDetailsTaxonomies(payload) {
            // submit
            this.updateTrackingDetailsTaxonomies(payload)
        },
        taxonomiesClinician() {
            // submit all taxonomies clinician
            this.region.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            this.program.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            this.clinicianAttributes.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            this.licenseType.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            this.insuranceTypesAcepted.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            this.specialities.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            this.languages.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            this.regionalCFEAssigned.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            this.profileTaxonomies({region: this.region, program: this.program, clinicianAttributes: this.clinicianAttributes})
            this.clinicianDetailsTaxonomies({licenseType: this.licenseType, insuranceTypesAcepted: this.insuranceTypesAcepted, specialities: this.specialities, languages: this.languages})
            this.trackingDetailsTaxonomies({regionalCFEAssigned: this.regionalCFEAssigned})
        },
        taxonomiesClients(payload) {
            // countryOfResidence,insuranceType,primaryLanguage,race,householdIncome,gamblingTypes,employmentStatus,educationLevels,involvedWithRecoveryGroups,gender
            payload.countryOfResidence.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            payload.insuranceType.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            payload.primaryLanguage.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            // payload.race.sort(function (a, b) {
            //     if(a.toLowerCase() > b.toLowerCase()) {
            //         return 1;
            //     }
            //     if(a.toLowerCase() < b.toLowerCase()) {
            //         return -1;
            //     }
            //     return 0;
            // })

            payload.householdIncome.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            // payload.gamblingTypes.sort(function (a, b) {
            //     if(a.toLowerCase() > b.toLowerCase()) {
            //         return 1;
            //     }
            //     if(a.toLowerCase() < b.toLowerCase()) {
            //         return -1;
            //     }
            //     return 0;
            // })

            // payload.employmentStatus.sort(function (a, b) {
            //     if(a.toLowerCase() > b.toLowerCase()) {
            //         return 1;
            //     }
            //     if(a.toLowerCase() < b.toLowerCase()) {
            //         return -1;
            //     }
            //     return 0;
            // })

            // payload.educationLevels.sort(function (a, b) {
            //     if(a.toLowerCase() > b.toLowerCase()) {
            //         return 1;
            //     }
            //     if(a.toLowerCase() < b.toLowerCase()) {
            //         return -1;
            //     }
            //     return 0;
            // })

            payload.involvedWithRecoveryGroups.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            payload.gender.sort(function (a, b) {
                if(a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                if(a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                return 0;
            })

            this.updateTaxonomiesClients(payload)
        },
        editarTaxonomia(payload) {
            var filterTaxonomy = payload.tags.filter(tag => {
                return tag !== payload.tag;
            })

            this.stringLabelTag = payload.tag
            this.arrayLabelTag = filterTaxonomy
            this.typeLabelTag = payload.type
        },
        eliminarTaxonomia(payload) {
            if(payload.type === 'region') {
                db.collection('usuarios').where('region', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.region = filterTaxonomy

                            db.collection('taxonomies').doc('clinician').update({
                                region: this.region
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'program') {
                db.collection('usuarios').where('program', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.program = filterTaxonomy

                            db.collection('taxonomies').doc('clinician').update({
                                program: this.program
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'clinicianAttributes') {
                db.collection('usuarios').where('clinicianAttributes', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to 0 records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.clinicianAttributes = filterTaxonomy

                            db.collection('taxonomies').doc('clinician').update({
                                clinicianAttributes: this.clinicianAttributes
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'licenseType') {
                db.collection('usuarios').where('licenseType', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.licenseType = filterTaxonomy

                            db.collection('taxonomies').doc('clinician').update({
                                licenseType: this.licenseType
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'insuranceTypesAcepted') {
                db.collection('usuarios').where('insuranceTypesAccepted', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.insuranceTypesAcepted = filterTaxonomy

                            db.collection('taxonomies').doc('clinician').update({
                                insuranceTypesAcepted: this.insuranceTypesAcepted
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'specialities') {
                db.collection('usuarios').where('specialities', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.specialities = filterTaxonomy

                            db.collection('taxonomies').doc('clinician').update({
                                specialities: this.specialities
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'languages') {
                db.collection('usuarios').where('languages', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.languages = filterTaxonomy

                            db.collection('taxonomies').doc('clinician').update({
                                languages: this.languages
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'regionalCFEAssigned') {
                db.collection('usuarios').where('regionalCFEAssigned', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.regionalCFEAssigned = filterTaxonomy

                            db.collection('taxonomies').doc('clinician').update({
                                regionalCFEAssigned: this.regionalCFEAssigned
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'countryOfResidence') {
                db.collection('clients').where('countryOfResidence', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.countryOfResidence = filterTaxonomy

                            db.collection('taxonomies').doc('client').update({
                                countryOfResidence: this.countryOfResidence
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'insuranceType') {
                db.collection('clients').where('insuranceType', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.insuranceType = filterTaxonomy

                            db.collection('taxonomies').doc('client').update({
                                insuranceType: this.insuranceType
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'primaryLanguage') {
                db.collection('clients').where('primaryLanguage', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.primaryLanguage = filterTaxonomy

                            db.collection('taxonomies').doc('client').update({
                                primaryLanguage: this.primaryLanguage
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'race') {
                db.collection('clients').where('race', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.race = filterTaxonomy

                            db.collection('taxonomies').doc('client').update({
                                race: this.race
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'householdIncome') {
                db.collection('clients').where('householdIncome', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.householdIncome = filterTaxonomy

                            db.collection('taxonomies').doc('client').update({
                                householdIncome: this.householdIncome
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'gamblingTypes') {
                db.collection('clients').where('gamblingTypes', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.gamblingTypes = filterTaxonomy

                            db.collection('taxonomies').doc('client').update({
                                gamblingTypes: this.gamblingTypes
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'employmentStatus') {
                db.collection('clients').where('employmentStatus', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.employmentStatus = filterTaxonomy

                            db.collection('taxonomies').doc('client').update({
                                employmentStatus: this.employmentStatus
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'educationLevels') {
                db.collection('clients').where('educationLevels', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.educationLevels = filterTaxonomy

                            db.collection('taxonomies').doc('client').update({
                                educationLevels: this.educationLevels
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'involvedWithRecoveryGroups') {
                db.collection('clients').where('involvedWithRecoveryGroups', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.involvedWithRecoveryGroups = filterTaxonomy

                            db.collection('taxonomies').doc('client').update({
                                involvedWithRecoveryGroups: this.involvedWithRecoveryGroups
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'gender') {
                db.collection('clients').where('gender', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.gender = filterTaxonomy

                            db.collection('taxonomies').doc('client').update({
                                gender: this.gender
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'referralOptions') {
                db.collection('clients').where('referralOptions', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.referralOptions = filterTaxonomy

                            db.collection('taxonomies').doc('client').update({
                                referralOptions: this.referralOptions
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'recoverySupportServices') {
                db.collection('clients').where('recoverySupportServices', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.recoverySupportServices = filterTaxonomy

                            db.collection('taxonomies').doc('client').update({
                                recoverySupportServices: this.recoverySupportServices
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            } else if(payload.type === 'paymentArrangement') {
                db.collection('clients').where('paymentArrangement', '==', payload.tag).get()
                .then(itemUser => {
                    this.$swal({
                        title: 'Are you sure?',
                        text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.paymentArrangement = filterTaxonomy

                            db.collection('taxonomies').doc('client').update({
                                paymentArrangement: this.paymentArrangement
                            })

                            this.$swal({
                                icon: 'success',
                                title: 'Deleted!',
                                text: 'Taxonomy has been deleted.',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        }
                    })
                })
            }
        },
        // confirmText() {
        //     this.$swal({
        //         title: 'Are you sure?',
        //         text: `This selecction has been assigned to x records`,
        //         icon: 'warning',
        //         showCancelButton: true,
        //         confirmButtonText: 'Yes, delete it!',
        //         customClass: {
        //             confirmButton: 'btn btn-primary',
        //             cancelButton: 'btn btn-outline-danger ml-1',
        //         },
        //         buttonsStyling: false,
        //     }).then(result => {
        //         if(result.value) {
        //             this.$swal({
        //                 icon: 'success',
        //                 title: 'Deleted!',
        //                 text: 'Your file has been deleted.',
        //                 customClass: {
        //                 confirmButton: 'btn btn-success',
        //                 },
        //             })
        //         }
        //     })
        // },
    },
    created() {
        // get taxonomies clinician
        db.collection('taxonomies').doc('clinician')
        .onSnapshot(docTaxonomia => {
            // profile fields
            this.region = docTaxonomia.data().region
            this.program = docTaxonomia.data().program
            this.clinicianAttributes = docTaxonomia.data().clinicianAttributes

            // clinician details fields
            this.licenseType = docTaxonomia.data().licenseType
            this.insuranceTypesAcepted = docTaxonomia.data().insuranceTypesAcepted
            this.specialities = docTaxonomia.data().specialities
            this.languages = docTaxonomia.data().languages

            // tracking details fields
            this.regionalCFEAssigned = docTaxonomia.data().regionalCFEAssigned
        })

        // get taxonomies client
        db.collection('taxonomies').doc('client')
        .onSnapshot(docTaxonomia => {
            this.countryOfResidence = docTaxonomia.data().countryOfResidence
            this.insuranceType = docTaxonomia.data().insuranceType
            this.primaryLanguage = docTaxonomia.data().primaryLanguage
            this.race = docTaxonomia.data().race
            this.householdIncome = docTaxonomia.data().householdIncome
            this.gamblingTypes = docTaxonomia.data().gamblingTypes
            this.employmentStatus = docTaxonomia.data().employmentStatus
            this.educationLevels = docTaxonomia.data().educationLevels
            this.involvedWithRecoveryGroups = docTaxonomia.data().involvedWithRecoveryGroups
            this.gender = docTaxonomia.data().gender
            this.referralOptions = docTaxonomia.data().referralOptions ? docTaxonomia.data().referralOptions : []
            this.recoverySupportServices = docTaxonomia.data().recoverySupportServices ? docTaxonomia.data().recoverySupportServices : []
            this.paymentArrangement = docTaxonomia.data().paymentArrangement ? docTaxonomia.data().paymentArrangement : []
        })
    }
}
</script>