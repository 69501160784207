<template>
    <b-card>
        <h4>Problem Gambling Service Delivery</h4>
        <hr>
        <validation-observer ref="addProblemGamblingServiceDelivery">
            <b-row>
                <b-col md="6">
                    <b-form-group
                        label="Number of Help Requests"
                        label-for="Number of Help Requests"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number of Help Requests"
                        >
                            <b-form-input
                                v-model="NoHelpRequests"
                                placeholder="Number of Help Requests"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group
                        label="Referrals: % of treatment eligible callers scheduled for an appointment with a PGRC private practitioner"
                        label-for="Referrals: % of treatment eligible callers scheduled for an appointment with a PGRC private practitioner"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Referrals: % of treatment eligible callers scheduled for an appointment with a PGRC private practitioner"
                        >
                            <b-form-input
                                v-model="EligibleReferrals"
                                placeholder="Referrals: % of treatment eligible callers scheduled for an appointment with a PGRC private practitioner"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Information Provided: % of callers receiving information only"
                        label-for="Information Provided: % of callers receiving information only"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Information Provided: % of callers receiving information only"
                        >
                            <b-form-input
                                v-model="CallerInfoProvided"
                                placeholder="Information Provided: % of callers receiving information only"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group
                        label="Attended a session with a PGRC private practitioner compared to scheduled an appointment with a PGRC private practitioner (%)"
                        label-for="Attended a session with a PGRC private practitioner compared to scheduled an appointment with a PGRC private practitioner (%)"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Attended a session with a PGRC private practitioner compared to scheduled an appointment with a PGRC private practitioner (%)"
                        >
                            <b-form-input
                                v-model="AttendedPGRCsession"
                                placeholder="Attended a session with a PGRC private practitioner compared to scheduled an appointment with a PGRC private practitioner (%)"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group
                        label="Admitted to treatment with a PGRC private practitioner compared to attended a session with a PGRC private practitioner (%)"
                        label-for="Admitted to treatment with a PGRC private practitioner compared to attended a session with a PGRC private practitioner (%)"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Admitted to treatment with a PGRC private practitioner compared to attended a session with a PGRC private practitioner (%)"
                        >
                            <b-form-input
                                v-model="AttendedPGRCPractitioner"
                                placeholder="Admitted to treatment with a PGRC private practitioner compared to attended a session with a PGRC private practitioner (%)"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number referred to PGRC private practitioners"
                        label-for="Number referred to PGRC private practitioners"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number referred to PGRC private practitioners"
                        >
                            <b-form-input
                                v-model="TotalReferredPGRC"
                                placeholder="Number referred to PGRC private practitioners"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number in treatment"
                        label-for="Number in treatment"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number in treatment"
                        >
                            <b-form-input
                                v-model="TotalTreatment"
                                placeholder="Number in treatment"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number admitted"
                        label-for="Number admitted"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number admitted"
                        >
                            <b-form-input
                                v-model="TotalAdmitted"
                                placeholder="Number admitted"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number discharged"
                        label-for="Number discharged"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number discharged"
                        >
                            <b-form-input
                                v-model="TotalDischarged"
                                placeholder="Number discharged"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number of problem gamblers in treatment"
                        label-for="Number of problem gamblers in treatment"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number of problem gamblers in treatment"
                        >
                            <b-form-input
                                v-model="TotalProblemGamblersTreatment"
                                placeholder="Number of problem gamblers in treatment"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number of family members in treatment"
                        label-for="Number of family members in treatment"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number of family members in treatment"
                        >
                            <b-form-input
                                v-model="TotalFamilyMembersTreatment"
                                placeholder="Number of family members in treatment"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number of unique persons treated"
                        label-for="Number of unique persons treated"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number of unique persons treated"
                        >
                            <b-form-input
                                v-model="TotalUniquesTreated"
                                placeholder="Number of unique persons treated"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number of treatment visits"
                        label-for="Number of treatment visits"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number of treatment visits"
                        >
                            <b-form-input
                                v-model="TotalTreatmentVisits"
                                placeholder="Number of treatment visits"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number of assessments completed this quarter (number of clients)"
                        label-for="Number of assessments completed this quarter (number of clients)"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number of assessments completed this quarter (number of clients)"
                        >
                            <b-form-input
                                v-model="TotalAssessmentsCompletedQuarter"
                                placeholder="Number of assessments completed this quarter (number of clients)"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number of remote sessions (phone)"
                        label-for="Number of remote sessions (phone)"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number of remote sessions (phone)"
                        >
                            <b-form-input
                                v-model="TotalRemoteSessionsPhone"
                                placeholder="Number of remote sessions (phone)"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number of remote sessions (video)"
                        label-for="Number of remote sessions (video)"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number of remote sessions (video)"
                        >
                            <b-form-input
                                v-model="TotalRemoteSessionsVideo"
                                placeholder="Number of remote sessions (video)"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number of group counseling sessions"
                        label-for="Number of group counseling sessions"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number of group counseling sessions"
                        >
                            <b-form-input
                                v-model="TotalGroupSessions"
                                placeholder="Number of group counseling sessions"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number of clinical supervision sessions"
                        label-for="Number of clinical supervision sessions"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number of clinical supervision sessions"
                        >
                            <b-form-input
                                v-model="TotalClinicalSessions"
                                placeholder="Number of clinical supervision sessions"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number of 'not on census' (engagement) counseling sessions"
                        label-for="Number of 'not on census' (engagement) counseling sessions"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number of 'not on census' (engagement) counseling sessions"
                        >
                            <b-form-input
                                v-model="TotalNotOnCensusSession"
                                placeholder="Number of 'not on census' (engagement) counseling sessions"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number referred to OASAS outpatient treatment programs"
                        label-for="Number referred to OASAS outpatient treatment programs"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number referred to OASAS outpatient treatment programs"
                        >
                            <b-form-input
                                v-model="TotalReferredOASASOutpatient"
                                placeholder="Number referred to OASAS outpatient treatment programs"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number referred to OASAS ATC programs"
                        label-for="Number referred to OASAS ATC programs"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number referred to OASAS ATC programs"
                        >
                            <b-form-input
                                v-model="TotalReferredOASASATC"
                                placeholder="Number referred to OASAS ATC programs"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number referred to OASAS residential programs"
                        label-for="Number referred to OASAS residential programs"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number referred to OASAS residential programs"
                        >
                            <b-form-input
                                v-model="TotalReferredOASASRedesidential"
                                placeholder="Number referred to OASAS residential programs"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Number of non-English speaking callers served"
                        label-for="Number of non-English speaking callers served"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number of non-English speaking callers served"
                        >
                            <b-form-input
                                v-model="TotalNonEnglishSpeakingCallersServed"
                                placeholder="Number of non-English speaking callers served"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Average number of sessions attended at discharge"
                        label-for="Average number of sessions attended at discharge"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Average number of sessions attended at discharge"
                        >
                            <b-form-input
                                v-model="AvNumberSessionsAtDischarge"
                                placeholder="Average number of sessions attended at discharge"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Referral Sources"
                        label-for="Referral Sources"
                    >
                        <v-select
                            v-model="ReferralSources"
                            placeholder="Referral Sources"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="optionsReferralSources"
                            multiple
                        />
                    </b-form-group>
                </b-col>
                <!-- <b-col md="6">
                    <b-form-group
                        label="Number of uninsured clients connected to insurance navigators"
                        label-for="Number of uninsured clients connected to insurance navigators"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="integer"
                            name="Number of uninsured clients connected to insurance navigators"
                        >
                            <b-form-input
                                v-model=""
                                placeholder="Number of uninsured clients connected to insurance navigators"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col> -->
            </b-row>
            <b-button
                variant="primary"
                class="mr-2 float-right"
                type="submit"
                @click.prevent="validationForm"
            >
            Save
            </b-button>
        </validation-observer>
    </b-card>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BTabs, BTab, BCardText, BCard, BCol, BRow, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validations
            required,
            confirmed,
            password,
            email,
            min,
            integer,
            url,
            alpha,
            between,
            digits,
            length,
            alphaDash,

            // fields
            NoHelpRequests: null,
            EligibleReferrals: null,
            CallerInfoProvided: null,
            AttendedPGRCsession: null,
            AttendedPGRCPractitioner: null,
            TotalReferredPGRC: null,
            TotalTreatment: null,
            TotalAdmitted: null,
            TotalDischarged: null,
            TotalProblemGamblersTreatment: null,
            TotalFamilyMembersTreatment: null,
            TotalUniquesTreated: null,
            TotalTreatmentVisits: null,
            TotalAssessmentsCompletedQuarter: null,
            TotalRemoteSessionsPhone: null,
            TotalRemoteSessionsVideo: null,
            TotalGroupSessions: null,
            TotalClinicalSessions: null,
            TotalNotOnCensusSession: null,
            TotalReferredOASASOutpatient: null,
            TotalReferredOASASATC: null,
            TotalReferredOASASRedesidential: null,
            TotalNonEnglishSpeakingCallersServed: null,
            AvNumberSessionsAtDischarge: null,
            ReferralSources: null,

            // options fields
            optionsReferralSources: [
                'Existing/past PGRC client',
                'Family Member/Self',
                'GA/GamAnon',
                'HOPEline',
                'Warm Transfer',
                'Internet Search',
                'Legal System',
                'Other',
                'Other service provider',
                'Number of PGRC private practitioners',
                'Number of PGRC private practitioners with active caseloads',
                'Number of PGRC private practitioners contracted with commercial insurance companies/plans (include percentage of total PGRC private practitioners)',
                'List of contracted insurance companies/plans',
                'Clinician Languages Available',
                'Total amount paid out via state funds',
                'Total amount paid out via commercial insurance',
                'Total amount paid out via copays',
                'Total amount paid out via sliding scale',
                'Total amount paid to PGRC private practitioners with state funds',
                'Number of individuals with no insurance coverage (include percentage of total unique clients)',
                'Number of individuals who could not pay copay (include percentage of total unique clients)',
                'Number of individuals whose insurance reimbursement is less than PGRC rate (include percentage of total unique clients)',
                'Total amount paid to OASAS certified programs with state funds',
                'Number of clients whose treatment is covered by: State funds',
                'Number of clients whose treatment is covered by: Commercial insurance with copay',
                'Number of clients whose treatment is covered by: Commercial insurance without copay',
                'Number of clients whose treatment is covered by: Self pay'
            ]
        }
    },
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BCardText,
        BTabs,
        BTab,
        BCard,
        BCol,
        BRow,
        BFormTextarea,
        BFormCheckbox,
        vSelect,

        ValidationProvider,
        ValidationObserver,
    },
    methods: {
        ...mapActions(['updateProblemGamblingServiceDelivery']),
        validationForm() {
            this.$refs.addProblemGamblingServiceDelivery.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.submitProblemGamblingServiceDelivery({
                        NoHelpRequests: this.NoHelpRequests,
                        EligibleReferrals: this.EligibleReferrals,
                        CallerInfoProvided: this.CallerInfoProvided,
                        AttendedPGRCsession: this.AttendedPGRCsession,
                        AttendedPGRCPractitioner: this.AttendedPGRCPractitioner,
                        TotalReferredPGRC: this.TotalReferredPGRC,
                        TotalTreatment: this.TotalTreatment,
                        TotalAdmitted: this.TotalAdmitted,
                        TotalDischarged: this.TotalDischarged,
                        TotalProblemGamblersTreatment: this.TotalProblemGamblersTreatment,
                        TotalFamilyMembersTreatment: this.TotalFamilyMembersTreatment,
                        TotalUniquesTreated: this.TotalUniquesTreated,
                        TotalTreatmentVisits: this.TotalTreatmentVisits,
                        TotalAssessmentsCompletedQuarter: this.TotalAssessmentsCompletedQuarter,
                        TotalRemoteSessionsPhone: this.TotalRemoteSessionsPhone,
                        TotalRemoteSessionsVideo: this.TotalRemoteSessionsVideo,
                        TotalGroupSessions: this.TotalGroupSessions,
                        TotalClinicalSessions: this.TotalClinicalSessions,
                        TotalNotOnCensusSession: this.TotalNotOnCensusSession,
                        TotalReferredOASASOutpatient: this.TotalReferredOASASOutpatient,
                        TotalReferredOASASATC: this.TotalReferredOASASATC,
                        TotalReferredOASASRedesidential: this.TotalReferredOASASRedesidential,
                        TotalNonEnglishSpeakingCallersServed: this.TotalNonEnglishSpeakingCallersServed,
                        AvNumberSessionsAtDischarge: this.AvNumberSessionsAtDischarge,
                        ReferralSources: this.ReferralSources,
                    })
                }
            })
        },
    },
    created() {
        db.collection('PGSD').doc('reporting').get()
        .then(doc => {
            if(doc.exists) {
                this.NoHelpRequests = doc.data().NoHelpRequests ? doc.data().NoHelpRequests : null
                this.EligibleReferrals = doc.data().EligibleReferrals ? doc.data().EligibleReferrals : null
                this.CallerInfoProvided = doc.data().CallerInfoProvided ? doc.data().CallerInfoProvided : null
                this.AttendedPGRCsession = doc.data().AttendedPGRCsession ? doc.data().AttendedPGRCsession : null
                this.AttendedPGRCPractitioner = doc.data().AttendedPGRCPractitioner ? doc.data().AttendedPGRCPractitioner : null
                this.TotalReferredPGRC = doc.data().TotalReferredPGRC ? doc.data().TotalReferredPGRC : null
                this.TotalTreatment = doc.data().TotalTreatment ? doc.data().TotalTreatment : null
                this.TotalAdmitted = doc.data().TotalAdmitted ? doc.data().TotalAdmitted : null
                this.TotalDischarged = doc.data().TotalDischarged ? doc.data().TotalDischarged : null
                this.TotalProblemGamblersTreatment = doc.data().TotalProblemGamblersTreatment ? doc.data().TotalProblemGamblersTreatment : null
                this.TotalFamilyMembersTreatment = doc.data().TotalFamilyMembersTreatment ? doc.data().TotalFamilyMembersTreatment : null
                this.TotalUniquesTreated = doc.data().TotalUniquesTreated ? doc.data().TotalUniquesTreated : null
                this.TotalTreatmentVisits = doc.data().TotalTreatmentVisits ? doc.data().TotalTreatmentVisits : null
                this.TotalAssessmentsCompletedQuarter = doc.data().TotalAssessmentsCompletedQuarter ? doc.data().TotalAssessmentsCompletedQuarter : null
                this.TotalRemoteSessionsPhone = doc.data().TotalRemoteSessionsPhone ? doc.data().TotalRemoteSessionsPhone : null
                this.TotalRemoteSessionsVideo = doc.data().TotalRemoteSessionsVideo ? doc.data().TotalRemoteSessionsVideo : null
                this.TotalGroupSessions = doc.data().TotalGroupSessions ? doc.data().TotalGroupSessions : null
                this.TotalClinicalSessions = doc.data().TotalClinicalSessions ? doc.data().TotalClinicalSessions : null
                this.TotalNotOnCensusSession = doc.data().TotalNotOnCensusSession ? doc.data().TotalNotOnCensusSession : null
                this.TotalReferredOASASOutpatient = doc.data().TotalReferredOASASOutpatient ? doc.data().TotalReferredOASASOutpatient : null
                this.TotalReferredOASASATC = doc.data().TotalReferredOASASATC ? doc.data().TotalReferredOASASATC : null
                this.TotalReferredOASASRedesidential = doc.data().TotalReferredOASASRedesidential ? doc.data().TotalReferredOASASRedesidential : null
                this.TotalNonEnglishSpeakingCallersServed = doc.data().TotalNonEnglishSpeakingCallersServed ? doc.data().TotalNonEnglishSpeakingCallersServed : null
                this.AvNumberSessionsAtDischarge = doc.data().AvNumberSessionsAtDischarge ? doc.data().AvNumberSessionsAtDischarge : null
                this.ReferralSources = doc.data().ReferralSources ? doc.data().ReferralSources : null
            }
        })
    }
}
</script>

<style>

</style>