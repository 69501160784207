<template>
    <div>
      <b-sidebar
        id="sidebar-right-editar-usuario"
        bg-variant="white"
        right
        backdrop
        shadow
        no-header
        ref="sidebar_editar_tag"
      >
        <template #default="{ hide }">
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50" style="background-color: #fff;">
              <feather-icon
                  class="cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="hide"
              />
          </div>
          <div class="b-sidebar-body">
            <div>
              <b-form
                class="p-2"
                style="padding-top: 0px !important;"
                @submit.prevent="submitEditar()"
              >
                  <div style="margin-top: 10px; line-height: 30px; font-size: 15px;">
                      <span style="color: #204887; line-height: 23px; font-size: 13px;">Edit Tag</span>
                      <b-form-group
                        label="Tag"
                        style="margin-bottom: 0px !important;"
                      >
                        <b-form-input
                          id="full-name"
                          v-model="label"
                          autofocus
                          trim
                        />
                      </b-form-group>
                  </div>
                  <div style="margin-top: 10px; line-height: 30px; font-size: 15px;" v-if="typeLabelTag === 'paymentArrangement'">
                      <b-form-group
                        label="Conditional field"
                        style="margin-bottom: 0px !important;"
                      >
                        <b-form-input
                          id="full-name"
                          v-model="conditionsPArrangement"
                          autofocus
                          trim
                        />
                      </b-form-group>
                  </div>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="warning"
                      class="mt-2"
                      type="submit"
                      style="border-radius: 5px; border-color: #204887 !important; background-color: #204887 !important; float: right;"
                  >
                      Save
                  </b-button>
              </b-form>
            </div>
          </div>
        </template>
      </b-sidebar>
    </div>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
    BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip,
  } from 'bootstrap-vue'
  import { avatarText } from '@core/utils/filter'
  import flatPickr from 'vue-flatpickr-component'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { ref } from '@vue/composition-api'
  import { required, minLength } from 'vuelidate/lib/validators'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import { mapActions } from "vuex"
  import { db } from '@/firebase'
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      BFormTextarea,
      vSelect,
      BFormCheckbox,
      BFormCheckboxGroup,
      BFormGroup,
      BFormTimepicker,
      BAvatar,
  
      // date
      flatPickr,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
      'b-toggle': VBToggle,
      'b-tooltip': VBTooltip,
    },
    props: ['stringLabelTag','arrayLabelTag','typeLabelTag'],
    data() {
      return {
          label: '',
          conditionsPArrangement: '',
          arrayConditions: []
      }
    },
    watch: {
      stringLabelTag(val) {
          this.label = val
          
          this.conditionsPArrangement = ''
          this.arrayConditions = []
          if(this.typeLabelTag === 'paymentArrangement') {
              db.collection('taxonomies').doc('client').get()
              .then(docT => {
                var atomconditionsPArrangement = docT.data().conditionsPArrangement ? docT.data().conditionsPArrangement : []
                var filteratomconditionsPArrangement = atomconditionsPArrangement.filter(ele => ele.cond !== val)
                var filteratomconditionsPArrangementActual = atomconditionsPArrangement.filter(ele => ele.cond === val)
                if(filteratomconditionsPArrangementActual.length) {
                    filteratomconditionsPArrangementActual.forEach(item => {
                        this.conditionsPArrangement = item.label ? item.label : ''
                    })
                }
                this.arrayConditions = filteratomconditionsPArrangement
              })
          }
      }
    },
    validations: {
    },
    methods: {
      ...mapActions(['editarUsuario']),
      submitEditar() {
          var arrayLabelTag = this.arrayLabelTag
          var typeLabelTag = this.typeLabelTag
          var pushLabel = arrayLabelTag.push(this.label)
  
          if(typeLabelTag === 'region') {
              db.collection('taxonomies').doc('clinician').update({
                  region: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'program') {
              db.collection('taxonomies').doc('clinician').update({
                  program: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'clinicianAttributes') {
              db.collection('taxonomies').doc('clinician').update({
                  clinicianAttributes: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'licenseType') {
              db.collection('taxonomies').doc('clinician').update({
                  licenseType: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'insuranceTypesAcepted') {
              db.collection('taxonomies').doc('clinician').update({
                  insuranceTypesAcepted: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'specialities') {
              db.collection('taxonomies').doc('clinician').update({
                  specialities: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'languages') {
              db.collection('taxonomies').doc('clinician').update({
                  languages: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'regionalCFEAssigned') {
              db.collection('taxonomies').doc('clinician').update({
                  regionalCFEAssigned: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'countryOfResidence') {
              db.collection('taxonomies').doc('client').update({
                  countryOfResidence: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'insuranceType') {
              db.collection('taxonomies').doc('client').update({
                  insuranceType: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'primaryLanguage') {
              db.collection('taxonomies').doc('client').update({
                  primaryLanguage: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'race') {
              db.collection('taxonomies').doc('client').update({
                  race: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'householdIncome') {
              db.collection('taxonomies').doc('client').update({
                  householdIncome: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'gamblingTypes') {
              db.collection('taxonomies').doc('client').update({
                  gamblingTypes: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'employmentStatus') {
              db.collection('taxonomies').doc('client').update({
                  employmentStatus: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'educationLevels') {
              db.collection('taxonomies').doc('client').update({
                  educationLevels: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'involvedWithRecoveryGroups') {
              db.collection('taxonomies').doc('client').update({
                  involvedWithRecoveryGroups: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'gender') {
              db.collection('taxonomies').doc('client').update({
                  gender: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'referralOptions') {
              db.collection('taxonomies').doc('client').update({
                  referralOptions: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'recoverySupportServices') {
              db.collection('taxonomies').doc('client').update({
                  recoverySupportServices: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
          } else if(typeLabelTag === 'paymentArrangement') {
              db.collection('taxonomies').doc('client').update({
                  paymentArrangement: arrayLabelTag
              })
              .then(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'Taxonomy updated',
                          icon: 'EditIcon',
                          variant: 'success',
                      },
                  })
              })
  
              if(this.conditionsPArrangement) {
                  this.arrayConditions.push({cond: this.label, label: this.conditionsPArrangement})
                  db.collection('taxonomies').doc('client').update({
                      conditionsPArrangement: this.arrayConditions
                  })
                //   .then(() => {
                //       this.$toast({
                //           component: ToastificationContent,
                //           props: {
                //               title: 'Taxonomy updated',
                //               icon: 'EditIcon',
                //               variant: 'success',
                //           },
                //       })
                //   })
              }
          }
          this.$refs.sidebar_editar_tag.hide();
      }
    },
    setup() {
        return {
          avatarText,
        }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  .b-sidebar-backdrop {
      background-color: #fefff9 !important;
  }
  </style>